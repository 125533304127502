// /tax_exemptions
import apiCall from 'libs/utils/api/apiCall';
import { NexusRegionsResponse, TaxExemptionsResponse } from 'api/types';
import { SharedErrors } from 'libs/utils/api/types';

export const taxExemptionsScope = (path?) => `/tax_exemptions${path || ''}`;

// /tax_exemptions
export const getTaxExemption = () => {
  const url = taxExemptionsScope('');
  return apiCall.get<TaxExemptionsResponse, SharedErrors>({ url, unscoped: true });
};

export const createTaxExemption = values => {
  const url = taxExemptionsScope('');
  return apiCall.post<TaxExemptionsResponse, SharedErrors>({ url, data: values, unscoped: true });
};

export const updateTaxExemption = values => {
  const url = taxExemptionsScope('');
  return apiCall.patch<TaxExemptionsResponse, SharedErrors>({ url, data: values, unscoped: true });
};

export const getNexusRegions = () => {
  const url = taxExemptionsScope(`/nexus_regions`);
  return apiCall.get<NexusRegionsResponse, SharedErrors>({ url, unscoped: true });
};
