import * as React from 'react';
import { DirectUpload } from 'activestorage';
import Loader from 'styleguide/components/Loader/Loader';
import { FaCheck } from '@react-icons/all-files/fa/FaCheck';
import { FaTimesCircle } from '@react-icons/all-files/fa/FaTimesCircle';

const SimpleUploader = ({ onSuccess, ...props }) => {
  const [fileState, setFileState] = React.useState<'init' | 'uploading' | 'error' | 'complete'>('init');

  const handleChange = e => {
    setFileState('uploading');
    const upload = new DirectUpload(e.target.files[0], '/rails/active_storage/direct_uploads');
    upload.create((error, blob) => {
      if (error) {
        setFileState('error');
      } else {
        setFileState('complete');
        onSuccess(blob.signed_id);
      }
    });
  };

  return (
    <>
      <input type="file" onChange={handleChange} {...props} />
      {fileState === 'uploading' && <Loader size="sm" />}
      {fileState === 'complete' && <FaCheck color="green" />}
      {fileState === 'error' && <FaTimesCircle color="red" />}
    </>
  );
};

export default SimpleUploader;
